<template>
  <div class="evaluateTable">
    <div class="header">
      <i class="el-icon-alifanhui" @click="back"></i>
      <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden">{{Title}}</div>
      <i></i>
    </div>
    <div class="contenTips">
      <p>类型：{{type}}</p>
    </div>
    <el-table :data="tableData" :span-method="objectSpanMethod" border style="width: 98%;margin-left:1%;margin-bottom: 60px;" :header-cell-style="{'background':'#E8E8E8','font-weight':'bold','color':'black'}">
      <el-table-column prop="Item1" label="分类" width="70">
      </el-table-column>
      <el-table-column prop="Title" label="评分项">
      </el-table-column>
      <template v-if="typeTable == 1">
        <el-table-column prop="Score" label="掌握程度" width="80">
        </el-table-column>
      </template>
      <template v-if="typeTable == 2">
        <el-table-column prop="Score" label="评级" width="80">
        </el-table-column>
      </template>
      <template v-if="typeTable == 3">
        <el-table-column prop="Score" label="分值" width="80">
        </el-table-column>
      </template>
    </el-table>
    <div class="footerBtn">
      <div class="btnCss" @click="openPopup">使用评价表</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typeTable: "",
      typeTitle: "",
      Title:"",
      type: "",
      tableData: [],
      Item1: "", // 一级分类
      spanArr: [], //二维数组，用于存放单元格合并规则
      position: 0, //用于存储相同项的开始index

      popupShow: false,

      evaluateID: "",
    }
  },
  methods: {
    // 获取数据
    initData() {
      this.$axios.get('/api/Evaluate/EvaluateInfo?evaluateID=' + this.evaluateID, "").then(res => {
        var data = JSON.parse(res.data);
        console.log(data);
        if (data.code == 200) {
          this.tableData = data.data.EvaluateItems;
          this.Title=data.data.Title;
          this.type=data.data.Type;
          this.Item1 = this.tableData[0].Item1;
          this.rowspan(0, "Item1");
        }
      });
    },
    // 点击左上角返回
    back() {
      this.$router.push({ name: "evaluateStudy" });
    },
    // 点击使用评分表
    openPopup() {
      this.$router.push({ name: "evaluateContent" });
    },
    //表格单元格合并
    rowspan(idx, prop) {
      this.spanArr[idx] = [];
      this.position = 0;
      this.tableData.forEach((item, index) => {
        if (index === 0) {
          this.spanArr[idx].push(1);
          this.position = 0;
        } else {
          if (this.tableData[index][prop] === this.tableData[index - 1][prop]) {
            this.spanArr[idx][this.position] += 1; //有相同项
            this.spanArr[idx].push(0); // 名称相同后往数组里面加一项0
          } else {
            this.spanArr[idx].push(1); //同列的前后两行单元格不相同
            this.position = index;
          }
        }
      });
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      for (let i = 0; i < this.spanArr.length; i++) {
        if (columnIndex === i) {
          const _row = this.spanArr[i][rowIndex];
          const _col = _row > 0 ? 1 : 0;
          return {
            rowspan: _row,
            colspan: _col,
          };
        }
      }
    },
  },
  mounted() {
    this.typeTitle = this.cookies.kkGet("typeTable");
    this.evaluateID = this.cookies.kkGet("evaluateID");
    console.log(this.evaluateID)
    if (this.typeTitle == "检核表") {
      this.typeTable = 1;
    } else if (this.typeTitle == "评级表") {
      this.typeTable = 2;
    } else if (this.typeTitle == "评分表") {
      this.typeTable = 3;
    }
    console.log(this.typeTitle);
    this.initData();
  },
}
</script>

<style>
.evaluateTable {
  width: 100%;
  position: relative;
}
.evaluateTable .header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  color: #101010;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background: #f8f8f8;
  z-index: 999;
}
.evaluateTable .header i {
  margin: 0 18px;
  font-size: 20px;
  display: block;
  width: 30px;
}
.evaluateTable .contenTips {
  margin-top: 44px;
  padding: 10px;
}
.evaluateTable .contenTips p {
  color: #7e7e7e;
  margin: 5px 0;
}
.evaluateTable .footerBtn {
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
}
.evaluateTable .footerBtn .btnCss {
  width: 180px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 10px auto;
  color: #fff;
  background: #3294ff;
}
</style>